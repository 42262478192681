/* eslint-disable no-undef */
import Cookies from "js-cookie";

const getScript = (source, callback) => {
  const el = document.createElement("script");
  el.onload = callback;
  el.src = source;
  document.body.appendChild(el);
};

document.addEventListener("DOMContentLoaded", () => {
  // Get recaptcha cookie, if it doesn't exist don't execute this
  const recaptchaSiteKey = Cookies.get("recaptcha");
  if (recaptchaSiteKey === undefined) return;

  getScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`, function() {
    grecaptcha.ready(function() {
      // Hide recaptcha badge
      document.querySelector(".grecaptcha-badge").style.visibility = "collapse";

      // Fix for reserved namespace on buttons
      const submitButtons = document.querySelectorAll("input[name='submit'], button[name='submit']");
      submitButtons.forEach(button => {
        button.name = "submit_form";
      });

      // On form submit
      function recaptchaExecution(evt) {
        evt.preventDefault();
        const form = evt.currentTarget;
        // If not post don't get recaptcha
        if (form.method.toUpperCase() !== "POST") {
          form.removeEventListener("submit", recaptchaExecution);
        }
        // Get recaptcha token and add to form before submitting
        grecaptcha.execute(recaptchaSiteKey).then(function(token) {
          const el = document.createElement("input");
          el.name = "recaptcha";
          el.type = "hidden";
          el.value = token;
          form.appendChild(el);
          form.removeEventListener("submit", recaptchaExecution);
          form.submit();
          // Have to remove the input and add back the handler here
          // because if a user presses the back button the page
          // will be loaded from the bfcache
          el.remove()
          form.addEventListener('submit', recaptchaExecution)
        });
      }

      const forms = document.querySelectorAll("form");
      forms.forEach(form => {
        form.addEventListener("submit", recaptchaExecution);
      });
    });
  });
});
